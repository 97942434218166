import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Order } from '../../../../../models'
import { orderService } from '../../../../../services'
import { setBreadcrumbs } from '../../../../../store'
import {
  toDollarUSFormat,
  getFullName,
  getDetailsCrumbs,
  getGuestOrderBreadCrumbs,
  DomainTypes,
  getFlightOrderBreadCrumbs,
} from '../../../../../utils'
import { InfoBox, ErrorPage, OrderStatusPill, TabSelector } from '../../../../common'
import { FlightInfo, GuestInfo, OrderSkeleton, TransactionInfo } from '..'
import './OrderDetails.scss'
import { useFetchState } from '../../../../../hooks'
import { CabinCode } from '../../../../../models/order'

export const OrderDetails = () => {
  const dispatch = useDispatch()
  const { loyaltyNumber, lastName, firstName, id, partitionKey, flightId } = useParams()
  const fetchData = async () => await orderService.getOrder(id as string, partitionKey as string)
  const { fetchState, retryFetchData } = useFetchState<Order>(fetchData)
  const [selectedTab, setSelectedTab] = useState('Guest')

  useEffect(() => {
    if (flightId) {
      dispatch(setBreadcrumbs(getFlightOrderBreadCrumbs(flightId, id as string, partitionKey as string)))
    } else if (lastName || loyaltyNumber) {
      dispatch(
        setBreadcrumbs(
          getGuestOrderBreadCrumbs(loyaltyNumber as string, lastName as string, firstName as string, id as string, partitionKey as string),
        ),
      )
    } else {
      dispatch(setBreadcrumbs(getDetailsCrumbs(DomainTypes.ORDERS, { id: id as string, displayName: id as string })))
    }
  }, [])

  const renderInfoBoxes = (order: Order) => {
    return (
      <div className="info-boxes">
        <InfoBox iconName="food-and-drink" iconCategory="in-flight" header="Order type" info={order.type} />
        <InfoBox iconName="receipt" iconCategory="shop" header="Order total" info={`${toDollarUSFormat(order.currentOrder.actualTotalPrice)} USD`} />
        <InfoBox
          iconName="calendar"
          iconCategory="interface"
          header="Date placed"
          info={order.currentOrder.createdAt ? new Date(order.currentOrder.createdAt).toDateString() : 'Unknown'}
        />
        <InfoBox iconName="account-stroke" iconCategory="interface" header="Placed by" info={getFullName(order.currentOrder.createdBy)} />
        <InfoBox iconName="boarding-pass-stroke" iconCategory="terminal" header="Confirmation code" info={order.confirmationCode} />
        <InfoBox
          iconName="seat"
          iconCategory="in-flight"
          header="Cabin"
          info={`${order.cabinCode == CabinCode.FirstClass ? 'First Class' : 'Main Cabin'} (${order.flightInformation.operatingClassOfService})`}
        />
      </div>
    )
  }

  if (fetchState.errorCode) {
    return <ErrorPage errorCode={fetchState.errorCode} errorNotFoundMsg={'Order not found.'} handleRetryClick={retryFetchData} />
  }

  if (!fetchState.data) {
    return <OrderSkeleton />
  }

  return (
    <div className="order-details-container">
      <div className="main-header-container">
        <div className="main-title">
          <auro-header margin="both" size="none" level="1" display="600">
            {`Order #${fetchState.data.id}`}
          </auro-header>
          <OrderStatusPill status={fetchState.data.currentStatus.status} />
        </div>
        {renderInfoBoxes(fetchState.data)}
      </div>
      <div className="main-content">
        <TransactionInfo order={fetchState.data} />
        <div className="tab-details-container">
          {
            <TabSelector
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              options={[`Guest info (${fetchState.data.currentOrder.passengers.length})`, `Flight summary`]}
            />
          }
          <div className="tab-details-content">
            <div className="content-header">
              <auro-header margin="both" size="none" level="1" display="300">
                {(selectedTab.startsWith('Guest') && 'Guest information') || 'Flight summary'}
              </auro-header>
            </div>
            {selectedTab.startsWith('Guest') && <GuestInfo passengers={fetchState.data.currentOrder.passengers} />}
            {selectedTab.startsWith('Flight') && <FlightInfo flightInfo={fetchState.data.flightInformation} />}
          </div>
        </div>
      </div>
    </div>
  )
}
