import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid'
import { ActiveStateIcon, ErrorPage, ResultsTable } from '../../../../common'
import { productService } from '../../../../../services'
import { ProductInventory, Product, RestError } from '../../../../../models'
import { formatMinutes, toDateString, toTimeString } from '../../../../../utils'
import './FlightProductsTable.scss'

interface FlightProductsTableProps {
  exportFilename?: string
  productInventories: ProductInventory[]
  setTabOption: (option: string) => void
}

export type ProductInventoryView = Product & ProductInventory

interface FlightProductsTableState {
  viewModel?: ProductInventoryView[] // merge product and inventory to create a view of the data
  errorCode?: number
}

export const flightProductsColumns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Product name',
    flex: 1,
    valueFormatter: (params) => params.value ?? '-',
    renderCell: (params: GridRenderCellParams<ProductInventoryView>) => renderProductCell(params.row),
  },
  {
    field: 'id',
    headerName: 'Id',
    minWidth: 375,
    valueFormatter: (params) => params.value ?? '-',
  },
  {
    field: 'code',
    headerName: 'Code',
    minWidth: 100,
    valueFormatter: (params) => params.value ?? '-',
  },
  {
    field: 'categoryName',
    headerName: 'Category',
    valueFormatter: (params) => params.value ?? '-',
    minWidth: 120,
  },
  {
    field: 'isOfferedPreflight',
    headerName: 'Preflight',
    valueFormatter: (params) => params.value ?? '-',
    renderCell: (params) => <ActiveStateIcon isActive={params.value} />,
    minWidth: 120,
  },
  {
    field: 'isOfferedInflight',
    headerName: 'Inflight',
    valueFormatter: (params) => params.value ?? '-',
    renderCell: (params) => <ActiveStateIcon isActive={params.value} />,
    minWidth: 120,
  },
  {
    field: 'preorderEndTimeOffset',
    headerName: 'Pre-order cut-off',
    valueFormatter: (params) => {
      return formatMinutes(params.value)
    },
    renderCell: (params: GridRenderCellParams<ProductInventoryView>) =>
      renderCutOffTime(params.row.preorderEndTimeOffset, params.row.preorderEndTimeLocal),
    minWidth: 240,
  },
  {
    field: 'preorderCancelEndTimeOffset',
    headerName: 'Cancel cut-off',
    valueFormatter: (params) => {
      return formatMinutes(params.value)
    },
    renderCell: (params: GridRenderCellParams<ProductInventoryView>) =>
      renderCutOffTime(params.row.preorderCancelEndTimeOffset, params.row.preorderCancelEndTimeLocal),
    minWidth: 240,
  },
  {
    field: 'remainingQuantity',
    minWidth: 125,
    headerName: 'Remaining',
    headerAlign: 'right',
    align: 'right',
    valueFormatter: (params) => params.value ?? '-',
  },
  {
    field: 'maxQuantity',
    minWidth: 125,
    headerName: 'Max quantity',
    headerAlign: 'right',
    align: 'right',
    valueFormatter: (params) => params.value ?? '-',
  },
  {
    field: 'sourceName',
    minWidth: 370,
    headerName: 'Source',
  },
  {
    field: 'lastModified',
    minWidth: 400,
    valueFormatter: (params) =>
      params.value == null
        ? '-'
        : new Date(params.value).toLocaleDateString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            timeZoneName: 'short',
          }),
    headerName: 'Last Modified',
  },
]

export const renderCutOffTime = (offset: number, localTime: string) => {
  if (!offset || !localTime) {
    return '-'
  }

  return (
    <div>
      <div>{formatMinutes(offset)}</div>
      <div className="cutoffTimeLocal">{toDateString(localTime) + ' ' + toTimeString(localTime)}</div>
    </div>
  )
}

export const renderProductCell = (pi: ProductInventoryView) => {
  const hasImages = pi?.images && pi.images.length > 0
  return (
    <div className="product-row-container">
      <div className="product-image-container">
        {hasImages ? (
          <img alt={'Product Image'} src={pi.images[0]} />
        ) : (
          <auro-icon customSize category="in-flight" name="food-and-drink" data-testid="placeholder-icon" />
        )}
      </div>
      <div className="product-name-container">
        {pi?.name && pi?.id && pi?.code && (
          <>
            <span className="product-name">{pi.name}</span>
            <span className="product-id">{`${pi.id} | ${pi.code}`}</span>
          </>
        )}
        {!pi?.name && !pi?.id && pi?.code && (
          <span className="product-code-only" data-testid="product-code-only">
            {pi.code}
          </span>
        )}
      </div>
    </div>
  )
}

export const FlightProductsTable = (props: FlightProductsTableProps) => {
  const navigate = useNavigate()
  const [state, setState] = useState<FlightProductsTableState>({})

  useEffect(() => {
    fetchProductsByCode()
  }, [])

  const fetchProductsByCode = async () => {
    try {
      setState({ ...state, errorCode: undefined })
      const productCodes = props.productInventories.map((i) => i.code as string)
      const productsOnFlight = await productService.searchByProductCodes(productCodes)
      props.setTabOption(`Inventory (${productsOnFlight.length})`)
      setState({ ...state, viewModel: createViewModel(productsOnFlight, props.productInventories) })
    } catch (err) {
      setState({ ...state, errorCode: (err as RestError).statusCode ?? 500 })
    }
  }

  const createViewModel = (products: Product[], productInventories: ProductInventory[]): ProductInventoryView[] => {
    let productInventoryViews: ProductInventoryView[] = []

    productInventories.forEach((inventory) => {
      const productCode = inventory.code?.toUpperCase()

      const foundProduct = products.find((p) => {
        return p.code?.toUpperCase() === productCode
      })
      productInventoryViews.push({
        ...inventory,
        ...foundProduct,
      } as ProductInventoryView)
    })

    return productInventoryViews
  }

  const handleRowClick = (params: GridRowParams) => {
    navigate(`product/${params.row.id}`)
  }

  if (state.errorCode) {
    return <ErrorPage errorCode={state.errorCode} errorNotFoundMsg="Products not found." handleRetryClick={fetchProductsByCode} />
  }

  if (!state.viewModel) {
    return <auro-skeleton shape="rectangle" class="skeleton-table" />
  }

  return (
    <div className="resultsContainer">
      <ResultsTable
        exportFilename={props.exportFilename}
        columnVisibilityModel={{ code: false, id: false, sourceName: false, preorderCancelEndTimeOffset: false, lastModified: false }} // hide from webview, but show on exported view
        hideHeaderText={true}
        columns={flightProductsColumns}
        getRowId={(row) => row.code}
        handleRowClick={handleRowClick}
        items={state.viewModel}
      />
    </div>
  )
}
