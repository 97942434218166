import React from 'react'
import './SegmentedControl.scss'
import { TimeframeOption } from '../../../models'

interface SegmentedControlProps {
  options: TimeframeOption[] // Add more models as needed
  selectedOption: TimeframeOption
  setSelectedOption: (option: TimeframeOption) => void
  handleOnClick?: () => void
}

export const SegmentedControl = (props: SegmentedControlProps) => {
  const handleClick = (option: TimeframeOption) => {
    props.setSelectedOption(option)
    props.handleOnClick?.()
  }

  const renderSegmentOption = (option: TimeframeOption) => {
    const className = `select-option${option === props.selectedOption ? ' selected' : ''}`
    return (
      <div key={option} className={className} onClick={() => handleClick(option)}>
        {option}
      </div>
    )
  }

  return <div className="segments-container">{props.options.map((option) => renderSegmentOption(option))}</div>
}
