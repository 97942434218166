export interface AccountInformation {
  accountGuid: string | null
  loyaltyInformation: LoyaltyInformation | null
  email: string | null
  firstName: string
  lastName: string
}

export interface LoyaltyInformation {
  loyaltyNumber: string
  loyaltyAirlineCode: string
  tierStatus: string
  tierStatusDetail: string
}
