import React from 'react'
import { Order, Passenger } from '../../../../../models'
import { toDollarUSFormat } from '../../../../../utils'
import { PaymentInfo } from '../PaymentInfo/PaymentInfo'
import './TransactionInfo.scss'

interface TransactionInfoProps {
  order: Order
}

export const TransactionInfo = (props: TransactionInfoProps) => {
  const isCancelledOrder = props.order.currentStatus.status === 'Cancelled'

  const renderItemDetails = (pax: Passenger) => {
    const imgClassNames = `item${isCancelledOrder ? ' cancelled-img' : ''}`
    const textClassNames = `item${isCancelledOrder ? ' cancelled-text' : ''}`
    const priceClassNames = `prices-margin${isCancelledOrder ? ' item-price-discounted-text' : ' item-price-text '}`

    return (
      <div className="item-card-container" key={pax.sequenceId}>
        <auro-header margin="both" size="lg" level="5" display="400">
          {pax.firstName ?? pax.accountInformation?.firstName} {pax.lastName ?? pax.accountInformation?.lastName}
        </auro-header>
        {pax.items.length !== 0 &&
          pax.items.map((item) => {
            const hasDiscount = item.actualPrice !== item.originalPrice
            return (
              <div className="item-container" key={pax.sequenceId + item.id}>
                <div className={textClassNames}>
                  <span>1x</span>
                  {item.imageThumbnail ? (
                    <img className={imgClassNames} src={item.imageThumbnail} />
                  ) : (
                    <auro-icon customSize category="in-flight" name="food-and-drink" />
                  )}
                  <div className="item-labels">
                    <span className="item-name-text">{item.name}</span>
                    {item.discountText && <span className="sub-label">{item.discountText}</span>}
                  </div>
                </div>
                <div className="item-price-container">
                  {hasDiscount && (
                    <>
                      <auro-icon customSize category="shop" name="gift" />
                      <span className="item-price-discounted-text">{toDollarUSFormat(item.originalPrice)}</span>
                    </>
                  )}
                  <span className={priceClassNames}>{toDollarUSFormat(item.actualPrice)}</span>
                </div>
              </div>
            )
          })}
        {pax.items.length === 0 && <span className="no-items-text">No items</span>}
      </div>
    )
  }

  const renderTotalPrice = () => {
    const currentOrder = props.order.currentOrder
    const hasDiscount = currentOrder.actualTotalPrice !== currentOrder.originalTotalPrice
    return (
      <div className="total-price-container">
        <auro-header margin="both" size="none" level="5" display="400">
          Total
        </auro-header>
        <div>
          {(isCancelledOrder || hasDiscount) && (
            <auro-header class="total-discounted-price-text" margin="both" size="none" level="5" display="400">
              {toDollarUSFormat(props.order.currentOrder.originalTotalPrice)}
            </auro-header>
          )}
          <auro-header class="prices-margin" margin="both" size="none" level="5" display="400">
            {(!isCancelledOrder && toDollarUSFormat(props.order.currentOrder.actualTotalPrice)) || '$0.00'}
          </auro-header>
        </div>
      </div>
    )
  }

  return (
    <div className="transaction-container">
      <div className="content-header">
        <auro-header margin="both" size="none" level="1" display="300">
          Order details
        </auro-header>
      </div>
      <div className="order-summary-container">
        {props.order.currentOrder.passengers.map((pax) => renderItemDetails(pax))}
        <div>
          <hr />
          {renderTotalPrice()}
        </div>
      </div>
      {props.order.currentOrder.actualTotalPrice !== 0 && (
        <>
          <hr />
          <PaymentInfo paymentInfo={props.order.currentOrder.paymentInformation} />
        </>
      )}
    </div>
  )
}
