import { AccountInformation, PaymentInformation } from '..'
import { Passenger } from './passenger'

export interface OrderSummary {
  createdBy: AccountInformation
  createdAt: Date
  createdWith: string
  numberOfProductsOrdered: number
  originalTotalPrice: number
  actualTotalPrice: number
  paymentInformation: PaymentInformation | null
  passengers: Passenger[]
}
