import React from 'react'
import './TabSelector.scss'

interface TabSelectorProps {
  selectedTab: string
  setSelectedTab: Function
  options: string[]
}

export const TabSelector = (props: TabSelectorProps) => {
  return (
    <div className="tabs">
      {props.options.map((option, index) => {
        return (
          <span
            key={option}
            className={option.startsWith(props.selectedTab) ? 'tab selected-tab' : 'tab'}
            onClick={() => {
              props.setSelectedTab(option)
            }}
          >
            {option}
          </span>
        )
      })}
    </div>
  )
}
